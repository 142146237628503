import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export const useIsRouterChanging = (): {
  isChanging: boolean;
  hasError: boolean;
} => {
  const [isChanging, setIsChanging] = useState(false);
  const [hasError, setHasError] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const handleStart = () => {
      setIsChanging(true);
    };
    const handleStop = () => {
      setIsChanging(false);
    };

    const handleStopWithError = () => {
      setIsChanging(false);
      setHasError(true);
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStopWithError);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStopWithError);
    };
  }, [router]);

  return { isChanging, hasError };
};
