import { Locales, TranslationFunctions } from './i18n-types';
import { i18nObject } from './i18n-util';
import { loadLocaleAsync } from './i18n-util.async';

import {
  detectLocale,
  navigatorDetector,
  queryStringDetector,
} from 'typesafe-i18n/detectors';
import { Language } from 'Sombra/api';

function updateLLObject(locale: Locales): void {
  LL = i18nObject(locale)
}

export async function initI18n(locale: Locales): Promise<void> {
  await loadLocaleAsync(locale)
  updateLLObject(locale)
}

export let LL: TranslationFunctions;

export function detectLanguage(): Language {
  return detectLocale<Language>(
    Language.En,
    [Language.En, Language.Fr],
    navigatorDetector,
    queryStringDetector
  );
}
