import { DateTime } from 'luxon';
import { useContext } from 'react';

import { AppContext } from 'Components/AppFrame/AppContext';
import { AccessRole } from 'Sombra/api';
import { routes } from 'Src/routes';

type RouterPushObject =
  | string
  | {
      pathname: string;
      query?: Record<string, string | number | boolean>;
    };

export const useGetHomePageInfo = (): RouterPushObject => {
  const { userAccessToken } = useContext(AppContext);

  if (userAccessToken?.role === AccessRole.Employee) {
    return {
      pathname: routes.employeeTimeCardDailySummary.path,
      query: {
        date: DateTime.fromJSDate(new Date()).toISODate(),
      },
    };
  } else {
    return routes.shortTermCalendar.path;
  }
};
