import React from 'react';

import {
  CountryState,
  CurrencyType,
  Day,
  Language,
  PayPeriod,
  TemperatureUnitMeasure,
} from 'Sombra/api';
import { UserAccessToken, UserData } from 'Src/auth/client';

export interface AppContextProps {
  user?: UserData;
  isConnected: boolean;
  userAccessToken: UserAccessToken;
  tenantPreferences: {
    language: Language;
    startOfWeek: Day;
    payPeriod?: PayPeriod;
    startOfPayPeriod?: string;
    enforceWeatherValidation?: boolean;
    disablePublicHoliday?: boolean;
    holidayLocations?: CountryState[];
    timeRecordPeriod: number;
    enableSmallToolsAndSupplies?: boolean;
    enableNewCostPresetFeature?: boolean;
    enableNewScheduler?: boolean;
    areProjectSchedulesLinkedByDefault?: boolean;
    projectScheduleEmployeeLinkedWithEquipment?: boolean;
    enableTimecard?: boolean;
    temperatureUnit?: TemperatureUnitMeasure;
    logbookAPIKey?: string;
    isLogbookEnabled?: boolean;
    moveDailyLogRemoveResources?: boolean;
    currency?: CurrencyType;
  };
}

export const AppContext = React.createContext<AppContextProps>(
  {} as AppContextProps,
);
