import type { FormattersInitializer } from 'typesafe-i18n';

import type { Formatters, Locales } from './i18n-types';

export const initFormatters: FormattersInitializer<Locales, Formatters> =
  async () => {
    const formatters: Formatters = {
      uppercase: (value: string) => value.toUpperCase(),
    };

    return formatters;
  };
