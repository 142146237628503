import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { Auto404Page } from 'Components/5-pages/404Page';
import { Connecting } from 'Components/AppFrame/Connecting';
import { PATH_NAME } from 'Helpers/regex';
import { useGetHomePageInfo } from 'Src/hooks/useGetHomePageInfo';

import { useIsRouterChanging } from '../hooks/useIsRouterChanging';

export default function IndexPage(): JSX.Element {
  const router = useRouter();
  const { isChanging: isRouterChanging, hasError } = useIsRouterChanging();
  const homePageInfo = useGetHomePageInfo();
  useEffect(() => {
    if (!isRouterChanging && !hasError) {
      const currentPath = router.asPath.replace(PATH_NAME, '');

      const link = currentPath === '/' ? homePageInfo : router.asPath;

      router.push(link);
    }
  }, [hasError, isRouterChanging, homePageInfo, router]);

  return !hasError ? <Connecting /> : <Auto404Page />;
}
