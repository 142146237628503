import Link from 'next/link';
import React from 'react';

import { useGetHomePageInfo } from 'Src/hooks/useGetHomePageInfo';
import { LL } from 'Src/i18n';

import Image404Asset from '../../404-image2x.png';

//TODO: Add link to go back home
export const ConnectedErrorPage: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const homePageInfo = useGetHomePageInfo();
  return (
    <div className={'flex flex-col justify-center items-center h-full'}>
      <div className={'w-[284px] h-[284px] mb-10'}>
        <img className={'w-[400px]'} src={Image404Asset} />
      </div>
      <div className={'text-gray-800 text-4xl font-bold mb-4 uppercase'}>
        {LL.components.Connected404Page.title()}
      </div>
      <div className={'text-zinc-600 text-2xl text-center leading-7 mb-20'}>
        {LL.components.Connected404Page.description()}
      </div>
      <Link
        passHref
        href={homePageInfo}
        className={'text-brand-blue-dark hover:underline text-2xl'}
      >
        {LL.components.Connected404Page.home()}
      </Link>
    </div>
  );
};
